<template>
  <desactive :activation="openDesactiv" v-if="openDesactiv" :message="message" @oga="shortDown"/>
  <div class="flex w-full">
    <div class="w-1/2 bg-yell-clear rounded-5 flex p-6 pl-8 text-left">
      <div class="w-1/2">
        <div>Code d’activation</div>
        <div
            class="flex items-center cursor-pointer"
            v-clipboard:copy="commercial.person.businessCode"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
        >
          <div class="font-c8 text-c32">
            {{ commercial.person.businessCode }}
          </div>
          <icon
              :data="icons.copy"
              height="40"
              width="40"
              class="ml-2 cursor-pointer"
              original
          />
        </div>
      </div>

      <div class="diviser" />

      <div class="w-1/2 pl-14">
        <div class="text-c14">
          Utilisation
        </div>
        <div class="flex items-center">
          <div class="font-c8 text-c32"> {{ allClient.length }} </div>
          <icon
              :data="icons.haut"
              height="15"
              width="15"
              class="ml-2 mt-3 cursor-pointer"
              original
          />
          <div class="text-c12 mt-3 ml-2">+1.10% cette semaine</div>
        </div>
      </div>
    </div>


    <div class="w-1/2 ml-6 bg-white rounded-5 flex p-6 pl-8 text-left">
      <div class="w-1/2">
        <div class="flex items-center">
          <icon
              :data="icons.entreprise"
              height="20"
              width="20"
              class="cursor-pointer"
              original
              color="#000"
          />
          <div class="text-c16 text-83 ml-2">SFD</div>
        </div>

        <div class="font-c8 text-c22 mt-2">{{ commercial.sfd.name }}</div>
        <div class="text-a italic text-c12 mt-2">Ajouté le {{ new Date(commercial.info.createdAt).toLocaleDateString() }}</div>
      </div>

      <div class="diviser" />

      <div class="w-1/2 flex items-center pl-14 pr-10">
        <buton
            v-if="commercial.person.status === 'ACTIVE'"
            label="Désactiver"
            class="w-full"
            background="#31B049"
            :icon="icons.desactiv"
            @oga="backDesactiv"
            :charge="charge"
        />

        <buton
            v-if="commercial.person.status !== 'ACTIVE'"
            label="Désactivé"
            class="w-full"
            background="#EDEDEE"
            color="#AAAAB0"
            :icon="icons.desctivate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import copy from '../../../assets/icons/ic-copy.svg'
import haut from '../../../assets/icons/ic-haut.svg'
import desctivate from '../../../assets/icons/ic-desactivate.svg'
import entreprise from '../../../assets/icons/ic-entreprise.svg'
import desactiv from '../../../assets/icons/ic-desactive.svg'
import buton from '../../helper/add/button'
import desactive from '../../popup/desactivate'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    buton,
    desactive
  },

  props: {
    allClient: {
      type: Array,
      default: null,
    }
  },

  data () {
    return {
      icons: {
        arrow,
        copy,
        haut,
        entreprise,
        desactiv,
        desctivate
      },
      charge: false,
      commercial: null,
      openDesactiv: false,
      message: '',
    }
  },

  created () {
    this.commercial = this.$store.getters.trackCommercial
  },

  methods: {
    onCopy (a) {
      console.log(a)
    },

    onError (a) {
      console.log(a)
    },

    backDesactiv (){
      this.message = 'Vous êtes sur le point de désactiver le commercial '
          + this.commercial.person.firstName + ' ' +
          this.commercial.person.lastName +
          ', son code d’activation ne sera plus valide après cette action'
      this.openDesactiv = true
    },

    shortDown () {
      this.openDesactiv = false
      this.charge = true

      http.post(apiroutes.baseURL + apiroutes.desactiveCommercial, {
        commercialId: this.commercial.person.id,
        status: 'SUSPENDED'
      })
          .then(response => {
            this.commercial.person = response
            // console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    },

    // copier () {
    //   document.exeCommand('copy')
    // }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  width: 0.5px;
  background-color: #E0E195;
}
</style>
